import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logo from "../assets/images/horizon-full.svg";
import LogoWhite from "../assets/images/horizon-full.svg";
import UserImage from "../assets/images/user.png";
import Avatar12 from "../assets/images/xs/avatar12.jpg";
import Avatar11 from "../assets/images/xs/avatar11.jpg";
import Avatar4 from "../assets/images/xs/avatar4.jpg";
import Avatar5 from "../assets/images/xs/avatar5.jpg";
import Avatar2 from "../assets/images/xs/avatar2.jpg";
import Avatar1 from "../assets/images/xs/avatar1.jpg";
import Avatar3 from "../assets/images/xs/avatar3.jpg";
import { logOut } from "../store/reducer/login"
import _ from 'lodash';
function NavBar() {
    const dispatch = useDispatch();
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    //let activeKey = activeKey;
    //console.log(activeKey, "activeKey");
    const handleClick = () => {

    };
    const [menuDetails, setMenuDetails] = useState([]);
    const [toggleMenu, setToggleMenu] = useState(true);
    let [activeKey, setactiveKey] = useState(res);
    const activeMenutabContainer = (id) => {
        var parents = document.getElementById("main-menu");
        var activeMenu = document.getElementById(id);
        let check = 0;
        for (let index = 0; index < parents.children.length; index++) {
            if (parents.children[index].id !== id) {
                parents.children[index]?.classList.remove("active");
                parents.children[index].children[1]?.classList.remove("in");
            } else {
                check = 1;
                parents.children[index]?.classList.add("active");
            }
        }
        console.log(check, "check");
        setTimeout(() => {
            console.log("in");
            if (check == 1) {
                activeMenu.classList.add("active");
                activeMenu.children[1].classList.add("in");
            }
            else {
                activeMenu.classList.toggle("active");
                activeMenu.children[1].classList.toggle("in");
            }
        }, 100);

    };
    const user_name = localStorage.getItem("username")

    const onToggleMenu = () => {
        setToggleMenu(!toggleMenu);
        if (!toggleMenu) {
            document.body.classList.remove("layout-fullwidth");
        } else {
            document.body.classList.add("layout-fullwidth");
        }
    };

    const MarkActiveSub = (active, sub) => {
        activeKey = sub;
        setactiveKey(sub);
    }

    const logout = () => {
        dispatch(logOut());
    }

    useEffect(() => {
        if (activeKey) {
            let getcontainer = menuDetails.filter((menu) => {
                if (_.includes(menu.url, activeKey)) {
                    return menu.container;
                }
            })
            //let get_c = _.some(menuDetails, (el) => _.includes(el.url, activeKey))

            //console.log(menuDetails, getcontainer[0].container, "menuDetails")
            if (getcontainer.length > 0)
                activeMenutabContainer(getcontainer[0].container)

        }
    }, [activeKey])


    return (

        <div>
            <nav className="navbar navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-btn">
                        <button
                            className="btn-toggle-offcanvas"
                            onClick={() => {
                                onToggleMenu()
                            }}
                        >
                            <i className="lnr lnr-menu fa fa-bars"></i>
                        </button>
                    </div>

                    <div className="navbar-brand">
                        <a href="/dashboard">
                            <img
                                src={
                                    document.body.classList.contains("full-dark")
                                        ? LogoWhite
                                        : Logo
                                }
                                alt="Horizon Logo"
                                className="img-responsive logo"
                            />
                        </a>
                    </div>

                    <div className="navbar-right">
                        <div id="navbar-menu">
                            <ul className="nav navbar-nav">
                                <li>
                                    <a href="javascript:void(0)" onClick={logout} className="icon-menu">
                                        <i className="icon-login"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </div >
    )


}
export default NavBar;