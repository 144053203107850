import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const tryLogin = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signin", postData);
};
export const forgotPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/forgot-password", postData);
};
export const resetPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/reset-password", postData);
};

/* Client*/
export const clientList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/client?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const clientAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(baseUrl, "baseUrl")
    return Http.post(baseUrl + "/client/add_client", postData, Http.getAuthToken());
};
export const clientUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/client/" + updateID, postData, Http.getAuthToken());
};
export const clientDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/client/" + deleteID, Http.getAuthToken());
};
export const clientStatus = (postData,supdateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/client/update_status", postData, Http.getAuthToken());
};