import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import Login from "./components/login";
import PrivateRoute from './PrivateRoute';
import Full from './view/full'
import RoutesLink from './components/routes';

import ForgotPassword from "./components/forgotpassword";
import ResetPassword from "./components/resetpassword";

import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "./components/dashboard";

function App() {
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/resetpassword/:token" element={<ResetPassword />} />
          <Route path="/" element={<PrivateRoute />} >
            <Route exact path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
