import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientList, clientAdd, clientUpdate, clientDelete, clientStatus } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "dashboard";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    statusloading: "initial",
    errorMessage: null,
    dashboardDatas: null,
    dashboardCount: 0
};

export const dashboardData = createAsyncThunk(
    `${namespace}/dashboardData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await clientList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddDashboard = createAsyncThunk(
    `${namespace}/AddDashboard`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await clientAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateDashboard = createAsyncThunk(
    `${namespace}/UpdateDashboard`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await clientUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteDashboard = createAsyncThunk(
    `${namespace}/DeleteDashboard`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await clientDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const changeStatusClient = createAsyncThunk(
    `${namespace}/changeStatusClient`,
    async ({ status, supdateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(supdateID, "interdata");
            let postData = { status: status, id: supdateID };
            const data = await clientStatus(postData, supdateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



const dashboardSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearCatgLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearCatgAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearCatgUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearCatgDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearCatgStatusLoading: (state) =>{
            state.statusloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [dashboardData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [dashboardData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.dashboardCount = payload?.data?.count;
            // state.dashboardDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count, "payload")
            state.dashboardCount = payloaddatas?.count;
            state.dashboardDatas = payloaddatas?.data;
        },
        [dashboardData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert();
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddDashboard.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddDashboard.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddDashboard.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateDashboard.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateDashboard.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateDashboard.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteDashboard.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteDashboard.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteDashboard.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [changeStatusClient.pending](state) {
            state.statusloading = API_STATUS.PENDING;
        },
        [changeStatusClient.fulfilled](state, { payload }) {
            state.statusloading = API_STATUS.FULFILLED;
        },
        [changeStatusClient.rejected](state, action) {
            state.statusloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearCatgAddLoading, clearCatgUpdateLoading, clearCatgDeleteLoading, clearErrormsg,clearCatgStatusLoading } = dashboardSlice.actions;

export const dashboardSelector = (state) => state.dashboard;

export default dashboardSlice.reducer;
